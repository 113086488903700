import moment from 'moment';
import { FormatedPlate } from '../interfaces/interfaces';

export class MethodsUtils {

    static async convertTimeToMilliseconds(timeInput: Date | string, includeSeconds: boolean = true): Promise<number> {
        let time;

        if (timeInput instanceof Date) {
            time = moment(timeInput).startOf('day');
        } else {


            if (timeInput.length === 4 && !timeInput.includes(':')) {
                timeInput = timeInput.slice(0, 2) + ':' + timeInput.slice(2);
            }

            
            const [hoursStr, minutesStr, secondsStr] = timeInput.split(':');
            const hours = parseInt(hoursStr, 10);
            const minutes = parseInt(minutesStr, 10);
            const seconds = parseInt(secondsStr || '0', 10); 

            return (hours * 3600000) + (minutes * 60000) + (includeSeconds ? seconds * 1000 : 0);
            
        }

        const milliseconds = time.hours() * 3600000 + time.minutes() * 60000 + (includeSeconds ? time.seconds() * 1000 : 0);

        return milliseconds;
    }

    
    static async convertMillisecondsToTime(milliseconds: number, includeSeconds: boolean): Promise<string> {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);

        if (includeSeconds) 
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
         else 
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }    



    static async diffHour(hourEnd: string, hourStart: string): Promise<number> {
        const hourFinally: number = await this.convertTimeToMilliseconds(hourEnd);
        const hourInit: number = await this.convertTimeToMilliseconds(hourStart);
        const result: number = hourFinally - hourInit;
        
        return result < 0 ? 0 : result;
    }

    static async intervalCalculated(hourEnd: string, hourStart: string, interval: string): Promise<string> {
        const diff: number = await this.diffHour(hourEnd, hourStart)
        const convInterval: number = await this.convertTimeToMilliseconds(interval, false);
        const result: number = diff - convInterval;

        return result > 0 ? await this.convertMillisecondsToTime(result, false) : await this.convertMillisecondsToTime(0, false);

    }

    static async isValidHour(timeString: string): Promise<boolean> {
        const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

        if (!timePattern.test(timeString)) {
            return false;
        }
        
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59);

    }

    // Indicar para o método a string que será tratada, o caracter que será removido, e o caracter que será colocado no lugar do removido
    // exemplo this.removeCharString("00:00", ":", "")  => RESULTADO "0000"
    static removeCharString(value: string, charRemove: string, charSub: string): string {
        return value.replace(charRemove, charSub);
    }

    // Formatar a Placa no Padrão Antigo BR - ABC-1234 ou Mercosul DYB-5G49
    static async formatAndValidatePlate(plate: string): Promise<FormatedPlate> {
        const regex = /^[A-Za-z]{3}\d{4}$|^[A-Za-z]{3}\d[A-Za-z]\d{2}$/;
        plate = plate.replace(/-/g, '');
        if (!regex.test(plate)) {
        return { valid: false, message: `Placa inválida: ${plate}. Use o formato ABC-1234 (Padrão Antigo BR) ou ABC-0A00 (Padrão Mercosul).` };
    }

    let formattedPlate;
        if (plate.length === 7) {
                formattedPlate = plate.slice(0, 3) + '-' + plate.slice(3);
        } else if (plate.length === 8) {
            formattedPlate = plate.slice(0, 4) + '-' + plate.slice(4);
        }
        return { valid: true, formatted: formattedPlate };
    }

    static FormatPlate(plate: string): string {
        let formattedPlate = "";
        
        if (plate.length === 7) {
            formattedPlate = plate.slice(0, 3) + '-' + plate.slice(3);
        } else if (plate.length === 8) {
            formattedPlate = plate.slice(0, 4) + '-' + plate.slice(4);
         }
         return formattedPlate 

    }

    static async UnformatedPlate(plate: string): Promise<string> {
        let formattedPlate = "";
        formattedPlate = plate.replace('-',"")
        return formattedPlate.toUpperCase();
    }

    static formatName(fullName: string): string {
      const names = fullName.trim().split(/\s+/);
  
        if (names.length === 1) {
            return names[0];
        }
        
            return `${names[0]} ${names[names.length - 1]}`;
    }

}