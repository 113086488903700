import React from 'react';
import styles from "./styles.module.scss";
import axios from 'axios';
import path from "path";
import { Button, Modal, Notification } from "rsuite";

interface SummaryQuestion {
    question: string;
    answer: boolean;
    answer_justification?: string;
    filePath: string;
    fileName: string;
}

interface NewChecklist {
    checklistCode: string;
    checklistName: string;
    checklistThird: string;
    checklistVehicle: string;
    checklistType: string;
    signFileName?: string;
    signPath?: string;
    questions: SummaryQuestion[];
}

interface ApiResponse {
    status: number;
    checklist: NewChecklist;
}

interface ChecklistHistoryState {
    questions: SummaryQuestion[];
    checklistDetails: Partial<NewChecklist>;
    loading: boolean;
    showModal: boolean;
    message: string;
    loadingButton: boolean;
}

export interface ReturnReportChecklist {
    status: number;
    message: string;
    path: string;
}

let checklistId: number = 0;

export default class ChecklistHistory extends React.Component<any, ChecklistHistoryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            questions: [],
            checklistDetails: {},
            loading: true,
            showModal: false,
            message: '',
            loadingButton: false
        };
    }


    async componentDidMount() {
        checklistId = this.props.values.filled_checklist_id;
        try {
            const response = await axios.get<ApiResponse>(`/checklist/findfull/${checklistId}`);
            if (response.data.checklist) {
                const checklist = response.data.checklist;
                this.setState({
                    questions: checklist.questions,
                    checklistDetails: {
                        checklistCode: checklist.checklistCode,
                        checklistName: checklist.checklistName,
                        checklistThird: checklist.checklistThird,
                        checklistVehicle: checklist.checklistVehicle,
                        checklistType: checklist.checklistType,
                        signFileName: checklist.signFileName,
                        signPath: checklist.signPath,
                    },
                    loading: false,
                });
            } else {
                console.warn("Nenhum checklist encontrado.");
                this.setState({ loading: false });
            }
        } catch (error) {
            console.error('Erro ao buscar os dados do checklist:', error);
            this.setState({ loading: false });
        }
    }

    handleLoadingButtonOn = () => {
        this.setState({ loadingButton: true })
    }

    handleLoadingButtonOff = () => {
        this.setState({ loadingButton: false })
    }

    generatePdf = async () => {
        this.handleLoadingButtonOn()

        try {
            const result = await axios.get(`/checklist/generatepdf/${checklistId}`);
            const pdfUrl: ReturnReportChecklist = {
                status: result.data.status,
                message: result.data.message,
                path: result.data.pathUrl
            }
            const { checklistDetails } = this.state;
            this.setState({ showModal: true, message: "Gerando Relatório #" + checklistDetails.checklistCode });

            if (pdfUrl.status === 200) {
                Notification.success({
                    title: "SUCESSO",
                    description: pdfUrl.message
                })
                const url: any = pdfUrl.path.split('.' + path.sep).pop();
                const fileName = url.split(path.sep).pop();
                const downloadLink = document.createElement('a');
                downloadLink.href = 'https://' + window.location.host.split('25565').join('25567') + `${url}`;
                downloadLink.setAttribute('download', fileName);
                downloadLink.target = '_blank';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

            } else {
                Notification.error({
                    title: "ERRO",
                    description: "Houve um erro ao gerar o relatório!!!"
                })
            }

            this.handleLoadingButtonOff()
        } catch (error) {
            Notification.error({
                title: "ERRO",
                description: "Houve um erro ao gerar o relatório!!!"
            });
            this.setState({ message: "Houve um erro ao gerar o relatório!!!" });
            this.handleLoadingButtonOff()
        } finally {
            this.setState({ showModal: false });
            this.handleLoadingButtonOff()
        }
    }


    render() {
        const { questions, checklistDetails, loading, showModal, message } = this.state;

        if (loading) {
            return <div>Carregando...</div>;
        }

        return (
            <div className={styles.container}>
                <h2>Detalhes Checklist:</h2>
                <div id="questions-content">
                    {questions.length > 0 ? (
                        <div>
                            {questions.map((question, index) => (
                                <div key={index} className={styles.question}>
                                    <p><strong>{question.question}</strong></p>
                                    {!question.fileName && (
                                        <p><strong>Resposta:</strong> {question.answer ? 'Aprovado' : 'Reprovado'}</p>
                                    )}
                                    {question.answer_justification && (
                                        <p><strong>Justificação:</strong> {question.answer_justification}</p>
                                    )}
                                    {question.fileName && (
                                        <p>
                                            <strong>Imagem:</strong> <br />
                                            <img
                                                className={styles.image}
                                                src={question.filePath}
                                            />
                                            <a rel="noreferrer" target="_blank" href={question.filePath.split("\\").join("/")}>
                                                <i className="fas fa-eye"></i>
                                            </a>
                                            <a rel="noreferrer" target="_blank" download href={question.filePath}>
                                                <i className="fas fa-download"></i>
                                            </a>
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>Nenhuma pergunta encontrada.</div>
                    )}
                </div>
                {checklistDetails.signFileName && checklistDetails.signPath && (
                    <div className={styles.signatureContainer}>
                        <h3>Assinatura:</h3>
                        <img
                            src={checklistDetails.signPath}
                            alt={checklistDetails.signFileName}
                            className={styles.signatureImage}
                        />
                        <Button onClick={this.generatePdf} appearance="primary" className={styles.generatePDFButton} loading={this.state.loadingButton}>
                            Gerar PDF
                        </Button>
                    </div>
                )}

                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header>
                        <Modal.Title>Geração de Relatório</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.message}</p>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}
