import React from "react";
import styles from "./styles.module.scss";
import { Button, Input, Notification } from "rsuite";
import axios from "axios";
import { setCookie, parseCookies, destroyCookie } from "nookies";

interface ChecklistPageState {
    cpf: string;
    loadingButton: boolean;
}

class ChecklistPage extends React.Component<any, ChecklistPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cpf: '',
            loadingButton: false
        };
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(): void {
        localStorage.removeItem("token")
    }

    formatDocument(document: string): string {
        document = document.replace(/\D/g, '');

        // If length suggests it's a CNPJ
        if (document.length > 11) {
            document = document.slice(0, 14);
            if (document.length <= 2) {
                return document;
            } else if (document.length <= 5) {
                return document.replace(/(\d{2})(\d{0,3})/, '$1.$2');
            } else if (document.length <= 8) {
                return document.replace(/(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3');
            } else if (document.length <= 12) {
                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4');
            } else {
                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
            }
        }

        // CPF formatting
        document = document.slice(0, 11);
        if (document.length <= 3) {
            return document;
        } else if (document.length <= 6) {
            return document.replace(/(\d{3})(\d{0,3})/, '$1.$2');
        } else if (document.length <= 9) {
            return document.replace(/(\d{3})(\d{3})(\d{0,3})/, '$1.$2.$3');
        } else {
            return document.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
        }
    }

    changeLoadingButtonOff() {
        this.setState({
            loadingButton: false
        })
    }

    changeLoadingButtonOn() {
        this.setState({
            loadingButton: true
        })
    }

    async handleSubmitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.changeLoadingButtonOn()

        destroyCookie(null, '@checklist-informations', {
            path: '/',
        });

        const unformattedCPF = this.state.cpf.replace(/\D/g, '');

        if (!unformattedCPF) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha o campo de CPF para prosseguir.",
                placement: "bottomEnd"
            })

            this.changeLoadingButtonOff()
            return
        }

        const user = await axios.get("/checklist/getUser/" + unformattedCPF);

        if (user.data.status !== 200) {
            Notification.error({
                title: "Erro!",
                description: "Usuário inativo, não habilitado para realizar o checklist ou não encontrado.",
                placement: "bottomEnd"
            })

            this.changeLoadingButtonOff()
            return;
        }

        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;

        if (cookieValue) {
            destroyCookie(null, 'checklist_driver_name', {
                path: '/',
            });
        }

        const userObj = {
            id: user.data.user_id,
            name: user.data.user_name,
            cpfCpnj: unformattedCPF,
            userThird: user.data.user_third
        }

        const userObjStringfy = JSON.stringify(userObj)

        setCookie(null, "checklist_driver_name", userObjStringfy, {
            maxAge: 60 * 60,        // 1 hora
            path: '/',
        })

        this.changeLoadingButtonOff()

        Notification.success({
            title: "Sucesso!",
            description: "Login efetuado com sucesso.",
            placement: "bottomEnd"
        })

        setTimeout(() => {
            window.location.href = '/checklist/list';
        }, 1500)
    }

    handleInputChange(value: string) {
        const formattedCPF = this.formatDocument(value);
        this.setState({ cpf: formattedCPF });
    }

    render() {
        return (
            <>
                <div className={styles.container}>
                    <img
                        src="correctBrasmegChecklistLogo.png"
                        alt="Logo Brasmeg"
                        className={styles.logo}
                    />
                    <div className={styles.containerForm}>
                        <form className={styles.form} onSubmit={this.handleSubmitForm}>
                            <Input
                                placeholder="Digite seu CPF/CNPJ..."
                                value={this.state.cpf}
                                onChange={(e) => this.handleInputChange(e)}
                            />
                            <Button
                                appearance="primary"
                                color="cyan"
                                loading={this.state.loadingButton}
                                type="submit">
                                Acessar
                            </Button>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default ChecklistPage;