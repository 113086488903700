import axios from "../../utilities/axios";
import { Event } from "../Event";
import { ParametersGlobals } from "../../core/parametersGlobals/ParametersGlobals";
import dayjs from 'dayjs';
import { Notification } from "rsuite";

import { contentType } from "mime-types";

export class RoleEvents extends Event {
    
  public onLoad = async () => {
    
  }

  
}
